<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>

        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="/" aria-current="page">Home</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a @click="gotodownload()">Download</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="blogs">Blogs</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <div class="blog-list-row">
              <div v-for="(data, i) in bloglist" :key="i" class="blog-list-item"
                @click="goto(data.link)">
                <div class="blog-list-img">
                  <img :src="data.poster" alt="">
                </div>
                <div class="blog-list-content">
                  <div class="blog-list-title">
                    {{ data.title }}
                  </div>
                  <div class="blog-list-descript">
                    {{ data.desc }}
                  </div>
                  <div class="blog-list-date">
                    {{ $global.formatDate(new Date().getTime(), 'dd/MM/yyyy') }}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="#">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2022 © All Rights Reserved <strong><a href="/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/blogspc.scss';

export default {
  name: 'Home',
  data () {
    return {
      pageName: 'home',
      bloglist: [
        {
          title: 'How to use same WhatsApp Account on Different phone?',
          poster: require('@/assets/blog-1.jpg'),
          desc: "If you are using WhatsApp and want to use the same account on a different phone, the answer is 'No' But if you are using GB WhatsApp and want to use the same account on a different phone, the answer is ",
          link: '/blog-1',
        },
        {
          title: 'GB WhatsApp Data Recovery Way',
          poster: require('@/assets/blog-3.webp'),
          desc: 'Many users opt to use GB WhatsApp over the standard version due to its additional features, especially for important conversations related to work or business. Consequently, the conversations and other data stored in GB Whats',
          link: '/blog-2',
        },
        {
          title: 'GBWhatsApp Channels',
          poster: require('@/assets/blog-cv-3.webp'),
          desc: "Great news, channel function is finally here on GBWhatsApp. It's like Telegram Channel, where you can share information and connect with your audience. That means you can enjoy all the channel goodness right from your favorite modified WhatsApp version. So, let's dive into the world of WhatsApp channels and discover how they can revolutionize your chatting experience and boost your business.",
          link: '/blogs/GBWhatsApp-Channels',
        },
        {
          title: 'All Your Questions About GB WhatsApp',
          poster: require('@/assets/blog-cv-4.webp'),
          desc: 'GB WhatsApp is also known as " WhatsApp GB", "Ogmods" app which has more unique features of WhatsApp. These include features such as attractive themes, the ability to hide internet status, enabling airplane mode, and more.',
          link: 'blog-4',
        },
        {
          title: "The 10 Best Apps for Spying someone else's WhatsApp",
          poster: require('@/assets/OTgyNGRhYzA4ZDU2NGEyYjM3ZjU2MDE0NzE4ZDIxZDJfeGNTRDRhandPMndkTUF1V2MwQVNreDZLdExXMUJPMGdfVG9rZW46SUt2SWJHOTVOb3hpR0t4aUdYTmMwMDBCbm9nXzE3MTg3ODM1MjA6MTcxODc4NzEyMF9WNA.webp'),
          desc: 'This is one of the most frequently asked questions on social media platforms. It is evident that, regardless of the reasons, people are very eager to know how to spy',
          link: 'blog-5',
        },
        {
          title: " Error: You Need The Official WhatsApp to Use This Account",
          poster: require('@/assets/blog6-1.webp'),
          desc: "Have you ever received this error message: \"You Need The Official WhatsApp to Use This Account\"? Many users of WhatsApp Mods like FM WhatsApp, WhatsApp Plus, OB WhatsApp, and other mods with smaller user bases have encountered this issue.",
          link: '/fix-you-need-official-whatsapp',
        }
      ],
    };
  },
  mounted () { },
  methods: {
    goto (url) {
      window.location.href = url;
    },
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
